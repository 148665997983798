<div class="dvb-schulung-main" *transloco="let t">
    <div class="header">
        <div class="row header-row">
            <div class="col-xs-12 header-box">
                <h1 [tooltip]="t('SCHULUNG.SCHULUNG')">{{ t('SCHULUNG.SCHULUNG') }}</h1>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-7 big-abstand-bottom">
            <h2>{{ t('SCHULUNG.SCHULUNG_AUSWAEHLEN') }}</h2>

            @for (schulung of schulungen; track schulung.state) {
                <dv-accordion [expanded]="currentStateName() === schulung.state"
                              [index]="$index"
                              (toggleEvent)="goTo(schulung)">
                    <div accordionHeader class="header">
                        <span>{{ t(schulung.titleKey) }}</span>
                    </div>
                    <div accordionBody>
                        <h3>{{ t('SCHULUNG.INHALT') }}</h3>
                        <p>{{ t(schulung.subjectKey) }}</p>
                        <h3 class="small-abstand-top">{{ t('SCHULUNG.ZIEL') }}</h3>
                        <p>{{ t(schulung.goalKey) }}</p>
                    </div>
                </dv-accordion>
            }
        </div>

        <div class="col-md-4 col-md-offset-1">
            <!-- CONTENT from sub-states -->
            <ui-view></ui-view>

            @if (currentStateName() === 'base.schulung') {
                <div>
                    <h2>{{ t('SCHULUNG.WIE_FUNKTIONIERTS') }}</h2>
                    <ul>
                        <li>{{ t('SCHULUNG.SCHULUNG_AUSWAEHLEN') }}</li>
                        <li>{{ t('SCHULUNG.SCHULUNG_ERSTELLEN') }}</li>
                        <li>{{ t('SCHULUNG.SCHULUNG_DURCHFUEHREN') }}</li>
                    </ul>
                    <p>{{ t('SCHULUNG.BESCHREIBUNG') }}</p>
                </div>
            } @else {
                <div class="big-abstand-top">
                    <dvlib-button-list>
                        <dvlib-loading-button (clickEvent)="createSchulungsMandant()"
                                              [isLoading]="isLoading()">
                            <span>{{ t('SCHULUNG.SCHULUNG_ERSTELLEN') }}</span>
                        </dvlib-loading-button>
                        @if (assets(); as assets) {
                            <a [attr.href]="assets" target="_blank">{{ t('SCHULUNG.UNTERLAGEN_ANSEHEN') }}</a>
                        }
                    </dvlib-button-list>

                    @if (currentSchulungsMandant(); as mandant) {
                        <div class="created-mandant-info">
                            <p>{{ t('SCHULUNG.SCHULUNGSBENUTZER_CREATED_AND_DATA_INITIALIZED') }}</p>
                            <p [innerHTML]="t('SCHULUNG.BENUTZERNAME_VALUE', {value: mandant?.name})"></p>
                            <p [innerHTML]="t('SCHULUNG.PASSWORT_VALUE', {value: mandant?.password})"></p>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</div>
